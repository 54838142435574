import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { theme } from 'styled-tools';
import NewsBlogFeed from 'components/NewsBlogFeed';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import livePlayerSidebarMenuItemShape from 'shapes/livePlayerSidebarMenuItemShape';

const NewsContainer = styled.div`
  height: 100%;
  color: ${theme('colors.white')};
  font-family: ${theme('typography.fontFamilySecondary')};
  overflow: hidden;
`;

const NewsBlogFeedWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 91%;
  overflow-y: ${({ isTouchDevice }) => (isTouchDevice ? 'auto' : 'hidden')};  
  overflow-x: hidden;

  &:hover {
    overflow-y: scroll;
    -webkit-padding-end: 0;

    &::-webkit-scrollbar {
      width: 0.5rem;
    }

    &::-webkit-scrollbar-thumb  {
      background: ${theme('colors.mutedGray')};
    }
  }
`;

const NewsHeader = styled(Toolbar)`
  padding: 0 0.625rem;
  width: 100%;
  background-color: ${theme('colors.darkCharcoal')};
  border-bottom: 1px solid ${theme('colors.mutedGray')};
  z-index: 1300;
  overflow: hidden;

  &.MuiToolbar-regular {
    min-height: 3rem;
  }
`;

const FeedTitle = styled(Typography)`
  font-family: ${theme('typography.fontFamilySecondary')};
  font-size: 0.75rem;
  font-weight: 700;
  margin-right: 0.5rem;
  text-transform: uppercase;
`;


News.propTypes = {
  data: PropTypes.arrayOf(livePlayerSidebarMenuItemShape),
  isTouchDevice: PropTypes.bool,
};

function News({ data, isTouchDevice }) {
  const { blogPostFeedBlock } = data[0];

  return (
    <NewsContainer isTouchDevice={isTouchDevice}>
      <NewsHeader>
        <FeedTitle>MARKET NEWS & INSIGHTS</FeedTitle>
      </NewsHeader>
      <NewsBlogFeedWrapper isTouchDevice={isTouchDevice}>
        {blogPostFeedBlock.map((blogPostFeed) => (
          <NewsBlogFeed display={'list'} blogPostFeed={blogPostFeed} key={blogPostFeed.uid}/>
        ))}
      </NewsBlogFeedWrapper>
    </NewsContainer>
  );
}

export default React.memo(News);